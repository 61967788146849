<template>
  <div v-if="value.length" :class="`roles roles--${variant}`">
    <strong class="roles__title" v-if="title">{{ title }}</strong>
    <div class="roles__list">
      <div
        class="roles__item"
        v-for="(item, index) in value"
        :key="index"
        :data-id="item.id"
        :data-email="item.email"
      >
        <div
          v-if="value.length <= 2"
          class="roles__avatar"
          :style="{ backgroundImage: `url(${item.avatar})` }"
        ></div>
        <div class="roles__content">
          <div class="roles__name">{{ item.name }}</div>
          <div v-if="item.title" class="roles__user-title">{{ item.title }}</div>
          <a v-if="item.phone" class="roles__phone" :href="`tel:${item.phone}`">{{ item.phone }}</a>
          <a v-if="item.email" class="roles__email" :href="`mailto:${item.email}`">
            {{ item.email }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    title: String,
  },

  computed: {
    variant() {
      return this.value.length <= 1 ? 'full' : 'compact'
    },
  },
}
</script>

<style lang="scss">
.roles {
  &--compact {
    .roles {
      &__avatar {
        width: 150px;
      }
      &__email {
        margin-top: 1rem;
      }

      &__item {
        border: 1px solid rgba($burgund, 0.3);
        padding: 1.5rem 1.75rem;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  &__title {
    margin-bottom: 1.5rem;
  }

  &__avatar {
    --_avatar-size: 200px;
    width: var(--avatar-size, var(--_avatar-size));
    aspect-ratio: 1/1.5;
    background-size: cover;
    background-position: center;

    flex-shrink: 0;
  }

  &__subtitle {
    color: rgba($dark, 0.6);
  }

  &__user-title {
    margin: 2px 0;
  }

  &__item {
    display: flex;
    gap: 1.5rem;
    border-radius: $border-radius;

    @include respond-below('phone') {
      flex-direction: column;

      .roles {
        &__name {
          margin-top: 10px;
        }
      }
    }
  }

  &__name {
    font-weight: 500;
  }

  &__email,
  &__phone {
    display: block;
    text-decoration: none;
    white-space: nowrap;

    margin-right: 10px;

    svg {
      margin-right: 7px;
    }
  }

  &__email {
    text-decoration: underline;
  }
}
</style>
