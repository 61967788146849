var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'route--' + _vm.view.toLowerCase(),
    _vm.isOnTop ? 'page--is-top' : 'page--is-scrolled',
    { 'page--nav-expanded': _vm.isExpanded },
  ],attrs:{"id":"app"}},[(_vm.$route.meta.navigation !== false)?_c('div',{staticClass:"nav",class:[
      `nav--${_vm.view.toLowerCase()}`,
      { [`nav--${_vm.view.toLowerCase()}-expanded`]: _vm.isExpanded },
    ]},[_c('div',{staticClass:"container"},[_c('NavigationComponent',{attrs:{"logo":_vm.logo,"title":_vm.projectName,"action":"https://malling.no","isOpen":_vm.isExpanded,"break-at":992,"animate":false,"items":_vm.items},on:{"update:isOpen":function($event){_vm.isExpanded=$event},"update:is-open":function($event){_vm.isExpanded=$event}},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"center",fn:function(){return [_c('div',{staticClass:"nav__items"},_vm._l((_vm.items),function(item){return _c('ActionComponent',_vm._b({},'ActionComponent',item,false))}),1),_c('ButtonComponent',{staticClass:"elder__navigation-component--primary",attrs:{"tag":"router-link","to":"#kontakt","theme":"primary","label":"Kontakt megler"}})]},proxy:true}:null],null,true)})],1)]):_vm._e(),_c('router-view'),(_vm.$route.meta.footer !== false)?_c('FooterComponent',{attrs:{"navigation":_vm.items,"theme":"mini","cookie-manager":_vm.CM}}):_vm._e(),_c('AssetComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }