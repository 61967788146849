<template>
  <div
    id="app"
    :class="[
      'route--' + view.toLowerCase(),
      isOnTop ? 'page--is-top' : 'page--is-scrolled',
      { 'page--nav-expanded': isExpanded },
    ]"
  >
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :title="projectName"
          action="https://malling.no"
          :isOpen.sync="isExpanded"
          :break-at="992"
          :animate="false"
          :items="items"
        >
          <template v-if="!isMobile" #center>
            <div class="nav__items">
              <ActionComponent v-for="item in items" v-bind="item" />
            </div>
            <ButtonComponent
              tag="router-link"
              to="#kontakt"
              class="elder__navigation-component--primary"
              theme="primary"
              label="Kontakt megler"
            />
          </template>
        </NavigationComponent>
      </div>
    </div>
    <router-view />
    <FooterComponent
      v-if="$route.meta.footer !== false"
      :navigation="items"
      theme="mini"
      :cookie-manager="CM"
    />
    <AssetComponent />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from './gdpr'

import { NavigationComponent } from 'vue-elder-navigation'
import ActionComponent from 'vue-elder-navigation/src/action'
import AssetComponent from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    project() {
      return this.$path('$store.state.Project.item')
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      return this.logos.dark
    },
    isDemo() {
      return this.item.demo || false
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    projectName() {
      return this.item.name || ''
    },
    address() {
      let address = this.$path('address', this.item) || {}
      return `${address.street || ''}, ${address.postcode || ''} ${address.city || ''}${
        address.county ? ', ' + address.county : ''
      }`
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    posts() {
      return this.$path('posts', this.item) || []
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return
      return this.redirect('Project', { scrollTo: 0 })
    },

    defaultItems() {
      return [
        {
          label: 'Nøkkelinfo',
          action: '#nokkelinfo',
        },
        {
          label: 'Beliggenhet',
          action: '#beliggenhet',
        },
        {
          label: 'Plantegninger',
          action: '#plantegninger',
        },
        {
          label: 'Fasiliteter',
          action: '#fasiliteter',
        },
        {
          label: 'Bærekraft',
          action: '#barekraft',
          condition: Boolean(this.$path('project.customFields.rent-environment.content')),
        },
        {
          label: 'Kontakt megler',
          action: '#kontakt',
          class: 'elder__navigation-component--primary',
          condition: this.isMobile,
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
        .map((i, index) => ({ ...i, order: (index + 1) * 10 }))
    },
    isMobile() {
      return window.innerWidth < 992
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()

    this.promise = this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    const title = this.$path('title', this.seo) || this.projectName

    return {
      titleTemplate: this.projectName
        ? `%s | ${title}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    ActionComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.page--nav-expanded {
  overflow: hidden;
  height: 100dvh;
}

.nav {
  position: sticky;
  width: 100%;
  background-color: var(--brand-primary);
  top: 0;
  z-index: get-layer('nav');
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);
  transition: background 150ms ease-in;

  &__items {
    text-align: center;
    flex-grow: 1;
    display: flex;
    justify-content: center;

    @include respond-below('tablet') {
      flex-direction: column;

      & + .elder__navigation-component--primary {
        width: 100%;
        display: block;
        padding-block: 0.5rem;
        text-align: center;
        margin-top: 1rem;
      }
    }
  }

  &--project-expanded,
  &--disclaimer-expanded {
    padding-bottom: 1rem;
    height: 100%;
    position: fixed;
    inset: 0;
    z-index: 9999;

    .elder__navigation-actions {
      display: flex !important;
      flex-direction: column;
      gap: 1rem;
      padding: 2.5rem;
      font-size: var(--font-size-h3);

      .elder__navigation-component {
        padding: 1rem;
        justify-content: center;
      }
    }
  }
}

.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>
