<template>
  <div>
    <slot />
    <Lead
      class="project-lead"
      :reference="{ onModel: 'Project', ref: item.id }"
      :fields="['!title', '!comment', '!upsell']"
      action-label="Send inn"
      :placeholders="{
        'contact.name': 'Navn',
        'contact.email': 'E-post',
        'contact.phone': 'Telefon',
      }"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.project-lead {
  max-width: 800px;
  margin: 0 auto;

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: inherit;
  }

  ::placeholder {
    color: inherit;
    opacity: 1; /* Firefox */
  }

  .lead__form {
    background-color: transparent;

    &-actions {
      justify-content: start;

      .elder-button {
        background-color: var(--brand-primary);
        color: var(--brand-primary-contrast);
        box-shadow: none;

        @include respond-below('phone') {
          width: 100%;
        }

        &:hover {
          background-color: css-darken('brand-primary', 5%);
        }
      }
    }

    .elder-input {
      &__label {
        display: none;
      }

      &__field {
        border-radius: 0;
        background: transparent;
        border-color: transparent transparent var(--brand-primary);
      }

      &__element {
        padding: 0.5rem 0;
      }
    }
  }
}
</style>
