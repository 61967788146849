<script setup>
import { ref, computed, watch } from 'vue'
import RichText from '@/components/RichText.vue'
import ScrollAnchor from '@kvass/scroll-anchor'

const props = defineProps({
  theme: {
    type: String,
    default: 'default',
    validator(val) {
      return ['default', 'primary'].includes(val)
    },
  },
  title: String,
  description: String,
  containerStyle: String,
  mobileCollapse: {
    type: Boolean,
    default: true,
  },
  scrollId: String,
})

const mobileShow = ref(false)
const screenWidth = computed(() => window.innerWidth)
const breakpoint = 993

const descriptionStyles = computed(() => ({
  maxHeight: props.description?.length < 800 ? '300px' : null,
  columnFill: props.description?.length < 800 ? 'auto' : 'balance',
}))
</script>

<template>
  <section
  
    :class="[`section section--theme-${theme}`, { 'section--mobile': screenWidth < breakpoint }]"
  >
    <ScrollAnchor v-if="scrollId" :value="scrollId" offset="-10rem" />
    <div
      v-if="!mobileCollapse || screenWidth > breakpoint"
      class="container"
      :style="containerStyle"
    >
      <div v-if="title || description" class="section__header">
        <h2 v-if="title" class="section__title">{{ title }}</h2>
        <RichText
          v-if="description"
          class="section__description"
          :value="description"
          :style="descriptionStyles"
        />
      </div>
      <slot />
    </div>
    <div
      v-if="mobileCollapse && screenWidth < breakpoint"
      class="container"
      :style="containerStyle"
      v-auto-animate
    >
      <button @click="mobileShow = !mobileShow" class="section__header--mobile">
        <h2 v-if="title" class="section__title">{{ title }}</h2>
        <FontAwesomeIcon :icon="['fal', mobileShow ? 'minus' : 'plus']" size="2x" />
      </button>
      <div v-if="mobileShow" style="margin-top: 2rem;">
        <RichText v-if="description" class="section__description" :value="description" />
        <slot />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.section {
  padding-block: 3rem;

  &--mobile {
    border-top: 1px solid rgba($burgund, 0.2);
    padding-block: 1rem;
  }

  &__title {
    margin: 0;
  }

  &__header {
    margin-bottom: 3rem;
    display: grid;
    gap: 2rem;

    @include respond-above('tablet') {
      grid-template-columns: repeat(3, 1fr);
    }

    &--mobile {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: var(--brand-primary);
    }
  }

  &__description {
    grid-column: 2 / -1;

    @include respond-above('tablet') {
      columns: 2;
      gap: 1rem;
    }

    & > * {
      break-inside: avoid-column;
      margin-block: 0 1.6rem;
    }
  }
}
</style>
