<template>
  <Features class="facilities-features" :value="features" align="center" background="transparent" />
</template>

<script>
import Features from './Features.vue'

const iconMap = {
  meetingRoomCenter: 'handshake',
  coffeShop: 'mug-hot',
  training: 'dumbbell',
  bikeParking: 'square-parking',
  broadband: 'wifi',
  view: 'mountain-sun',
  serviceApp: 'mobile',
  reception: 'bell-concierge',
  canteen: 'utensils',
  balcony: 'sun',
  wardrobes: 'clothes-hanger',
  garage: 'square-parking',
  charging: 'charging-station',
  bikeService: 'screwdriver-wrench',
  lift: 'elevator',
  alarm: 'light-emergency-on',
  drivein: 'garage',
  loading: 'truck-ramp-box',
  hiking: 'person-hiking',
  shirtCleaning: 'shirt-long-sleeve',
}

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    features() {
      return this.value.map(v => ({
        title: this.$tc(`rent-facilities:${v}`),
        preselectedIcon: ['fas', iconMap[v]],
      }))
    },
  },
  components: {
    Features,
  },
}
</script>
