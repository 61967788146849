<template>
  <div class="hero" v-if="items.length">
    <Slider :photo-swipe="true" :data="items"></Slider>
    <div class="hero__content container">
      <RichText class="hero__slogan" :value="description || item.description" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Slider from '@kvass/pagebuilder/src/components/Slider.vue'
import RichText from '@/components/RichText'

export default {
  props: {
    description: {
      type: String,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('Root', ['item']),
    items() {
      return this.images || []
    },
  },


  components: {
    Slider,
    RichText,
  },
}
</script>

<style lang="scss">
.hero {
}
</style>
