import { library } from '@fortawesome/fontawesome-svg-core'

/* Solid icons */
import { faTh as fasTh } from '@fortawesome/pro-solid-svg-icons/faTh'
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faArrowRight as fasArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faArrowLeft as fasArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faPenRuler as fasPenRuler } from '@fortawesome/pro-solid-svg-icons/faPenRuler'
import { faLeaf as fasLeaf } from '@fortawesome/pro-solid-svg-icons/faLeaf'
import { faCircleBolt as fasCircleBolt } from '@fortawesome/pro-solid-svg-icons/faCircleBolt'
import { faBuildings as fasBuildings } from '@fortawesome/pro-solid-svg-icons/faBuildings'
import { faHandshake as fasHandshake } from '@fortawesome/pro-solid-svg-icons/faHandshake'
import { faMobile as fasMobile } from '@fortawesome/pro-solid-svg-icons/faMobile'
import { faBellConcierge as fasBellConcierge } from '@fortawesome/pro-solid-svg-icons/faBellConcierge'
import { faUtensils as fasUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils'
import { faMugHot as fasMugHot } from '@fortawesome/pro-solid-svg-icons/faMugHot'
import { faSun as fasSun } from '@fortawesome/pro-solid-svg-icons/faSun'
import { faClothesHanger as fasClothesHanger } from '@fortawesome/pro-solid-svg-icons/faClothesHanger'
import { faDumbbell as fasDumbbell } from '@fortawesome/pro-solid-svg-icons/faDumbbell'
import { faChargingStation as fasChargingStation } from '@fortawesome/pro-solid-svg-icons/faChargingStation'
import { faSquareParking as fasSquareParking } from '@fortawesome/pro-solid-svg-icons/faSquareParking'
import { faScrewdriverWrench as fasScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons/faScrewdriverWrench'
import { faElevator as fasElevator } from '@fortawesome/pro-solid-svg-icons/faElevator'
import { faLightEmergencyOn as fasLightEmergencyOn } from '@fortawesome/pro-solid-svg-icons/faLightEmergencyOn'
import { faWifi as fasWifi } from '@fortawesome/pro-solid-svg-icons/faWifi'
import { faGarage as fasGarage } from '@fortawesome/pro-solid-svg-icons/faGarage'
import { faTruckRampBox as fasTruckRampBox } from '@fortawesome/pro-solid-svg-icons/faTruckRampBox'
import { faMountainSun as fasMountainSun } from '@fortawesome/pro-solid-svg-icons/faMountainSun'
import { faPersonHiking as fasPersonHiking } from '@fortawesome/pro-solid-svg-icons/faPersonHiking'
import { faShirtLongSleeve as fasShirtLongSleeve } from '@fortawesome/pro-solid-svg-icons/faShirtLongSleeve'

library.add(
  fasTh,
  fasExclamationCircle,
  fasArrowRight,
  fasArrowLeft,
  fasPenRuler,
  fasLeaf,
  fasCircleBolt,
  fasBuildings,
  fasHandshake,
  fasMobile,
  fasBellConcierge,
  fasUtensils,
  fasMugHot,
  fasSun,
  fasClothesHanger,
  fasDumbbell,
  fasChargingStation,
  fasSquareParking,
  fasScrewdriverWrench,
  fasElevator,
  fasLightEmergencyOn,
  fasWifi,
  fasGarage,
  fasTruckRampBox,
  fasMountainSun,
  fasPersonHiking,
  fasShirtLongSleeve
)

/* Regular icons */
import { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown'
import { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp'
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen'
import { faExternalLinkAlt as farExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkAlt'
import { faTrain as farTrain } from '@fortawesome/pro-regular-svg-icons/faTrain'
import { faTrainSubwayTunnel as farTrainSubwayTunnel } from '@fortawesome/pro-regular-svg-icons/faTrainSubwayTunnel'
import { faTrainTram as farTrainTram } from '@fortawesome/pro-regular-svg-icons/faTrainTram'
import { faBus as farBus } from '@fortawesome/pro-regular-svg-icons/faBus'
import { faSquareParking as farSquareParking } from '@fortawesome/pro-regular-svg-icons/faSquareParking'
import { faLockKeyhole as farLockKeyhole } from '@fortawesome/pro-regular-svg-icons/faLockKeyhole'
import { faFileLines as farFileLines } from '@fortawesome/pro-regular-svg-icons/faFileLines'

library.add(
  farArrowDown,
  farArrowRight,
  farArrowLeft,
  farPen,
  farArrowUp,
  farAngleDown,
  farExternalLinkAlt,
  farTrain,
  farTrainSubwayTunnel,
  farTrainTram,
  farBus,
  farSquareParking,
  farFileLines,
  farLockKeyhole,
)

/* Light icons */
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faMobile as falMobile } from '@fortawesome/pro-light-svg-icons/faMobile'
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faRulerTriangle as falRulerTriangle } from '@fortawesome/pro-light-svg-icons/faRulerTriangle'
import { faTag as falTag } from '@fortawesome/pro-light-svg-icons/faTag'
import { faSortNumericUp as falSortNumericUp } from '@fortawesome/pro-light-svg-icons/faSortNumericUp'
import { faCheckSquare as falCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare'
import { faToggleOn as falToggleOn } from '@fortawesome/pro-light-svg-icons/faToggleOn'
import { faMinus as falMinus } from '@fortawesome/pro-light-svg-icons/faMinus'

library.add(
  falExclamationCircle,
  falCalendar,
  falEnvelope,
  falMobile,
  falPlus,
  falRulerTriangle,
  falTag,
  falSortNumericUp,
  falToggleOn,
  falCheckSquare,
  falMinus
)

/* Brand icons */
import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'

library.add(fabFacebook, fabInstagram, fabLinkedin, fabTwitter)

/* Duotone icons */
import { faSyncAlt as fadSyncAlt } from '@fortawesome/pro-duotone-svg-icons/faSyncAlt'
import { faAngleDown as fadAngleDown } from '@fortawesome/pro-duotone-svg-icons/faAngleDown'
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird'
import { faFilePdf as fadFilePdf } from '@fortawesome/pro-duotone-svg-icons/faFilePdf'
import { faFileAlt as fadFileAlt } from '@fortawesome/pro-duotone-svg-icons/faFileAlt'
import { faFileImage as fadFileImage } from '@fortawesome/pro-duotone-svg-icons/faFileImage'

library.add(fadSyncAlt, fadAngleDown, fadSpinnerThird, fadFilePdf, fadFileAlt, fadFileImage)
